<template>
  <div style="min-height: 100vh;">
    <div class="relative">
      <div
        class="px40 pt20"
        :style="{
          width: $mq == 'mobile' ? 'calc(100% - 20px)' : 'calc(100% - 20px)'
        }"
      >
        <h6 class="mt16">Your Activity</h6>
        <div class="flex wrap">
          <div
            class="relative mt20"
            :class="[$mq == 'mobile' ? 'mxauto' : '']"
            style="width: 150px; height: 150px;"
          >
            <div class="absolute measure_label">
              <div class="fs24 nowrap mtauto mxauto">
                {{ takenCount }} / {{ purchasedCount }}
              </div>
              <div style="color: #AFAFAF" class="fs12 mt4 mbauto mxauto">
                Tests taken
              </div>
            </div>
            <canvas id="activityChart" width="400" height="400"></canvas>
          </div>
          <div
            class="vertical flex relative"
            :style="{ width: $mq == 'mobile' ? '100%' : '' }"
            style="top: 20px;"
          >
            <div class="myauto flex">
              <p
                v-if="takenCount > 0"
                style="margin-top: auto; margin-bottom: auto;"
                :class="[$mq == 'mobile' ? '' : 'ml40']"
                class="fw500 brown500"
              >
                Good job! Keep up the good work!
              </p>
              <p
                v-else
                style="margin-top: auto; margin-bottom: auto;"
                :class="[$mq == 'mobile' ? '' : 'ml40']"
                class="fw500 brown500"
              >
                Your test statistics will show up here.
              </p>
            </div>
            <div
              v-if="takenCount > 0"
              :class="[$mq == 'mobile' ? '' : 'ml40']"
              class="mt20 mbauto"
            >
              <stat :stat="averageGrade + '%'" title="Average Grade" />
            </div>
          </div>
        </div>
        <h6 class="mt40 mb20">Your tests</h6>
        <div v-if="$mq != 'mobile' && !loading">
          <div
            v-for="(v, k) in packageBreakdown"
            :key="k"
            class="mb12 inline-block p16 pl20 test_card vertical flex"
          >
            <p class="mt12 fs16 bold _title">{{ k }} Test</p>
            <p class="mt12 mbauto fs12 grey">
              Comprehensive quizzes that will test your aptitude and
              architectural knowledge.
            </p>
            <button
              v-if="available[k] && !isFree(k)"
              class="brown500 wfill mxauto"
              @click="takeQuiz(k)"
            >
              Take Test
            </button>
            <button
              class="brown500 wfill mxauto"
              v-if="isFree(k)"
              @click="takeQuiz(k)"
            >
              Retake Quiz
            </button>
            <p class="mt4 center grey fs12">
              {{ takenBreakdown[k] || 0 }}
              {{ isFree(k) ? "" : "/" + packageBreakdown[k] }} tests taken
            </p>
            <progressbar
              class="mt12 absolute"
              :progress="takenBreakdown[k]"
              :outOf="packageBreakdown[k]"
            />
          </div>
        </div>
        <div v-if="$mq == 'mobile'" class="vertical flex">
          <h3 class="mxauto" style="margin-top: auto">
            Acegate is only accessible from desktops or laptops
          </h3>
          <p class="mt4 mxauto brown500">
            This emulates the experience of taking an actual GATE exam
          </p>
          <p style="margin-bottom: auto;" class="mbauto mxauto">
            Go back <a href="/">home</a>
          </p>
        </div>
        <!-- div style="border: 1px solid #eee" class="flex mt8 p12 w400 br4">
          <span class="myauto">Full: {{takenBreakdown.full}} / {{packageBreakdown.full}}</span>
          <button @click="takeQuiz" class="mlauto brown400">Take Quiz</button>
        </div>
        <div style="border: 1px solid #eee" class="mt8 p12 w300 br4">
          Half: {{takenBreakdown.half}} / {{packageBreakdown.half}}
        </div -->
      </div>
      <div class="px40">
        <h6 class="mt40 mb20">Test History</h6>
        <div
          class="test_history"
          v-for="(t, ix) in gradedEntries"
          :key="'entries-' + ix"
        >
          <div class="px20 py16 centered vertical flex">
            <div class="mtauto fs14 bold">{{ t.title }} Test</div>
            <div style="color: #9a9a9a" class="mbauto fs12 mt4">
              {{ $bus.parseDate(t.created_at, "", "HH:mm a, Do MMM YYYY") }}
            </div>
          </div>
          <div class="mlauto myauto mr28">
            <span
              class="absolute fs10"
              style="position: absolute; bottom: 8px; right: 8px;"
            >
              {{ t.grade.marks }} / {{ t.grade.total }}
            </span>
            <button @click="openModal(t.id)" class="tftftf py4 px8 fs12 mr20">
              View Results
            </button>
          </div>
          <progressbar
            style="bottom: 0px;"
            height="5"
            class="absolute"
            :progress="t.grade.marks"
            :outOf="t.grade.total"
          />
          <div class="ui modal p40" :id="t.id">
            <div class="flex">
              <div>
                <h6 class="mb12">Your Score</h6>
                <p class="fs24">
                  {{ t.grade.marks }} marks
                  <span class="fs14">/ {{ t.grade.total }}</span>
                </p>
              </div>
            </div>
            <span v-if="t.grade.marks < t.grade.total">
              <h6 class="mt20">Incorrect Answers</h6>
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Question</th>
                    <th>Answers</th>
                    <th>Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="q in t.entry.questions.filter((q, i) => {
                      q.index = i;
                      return (
                        q.type != 'openEnded' && t.grade.wrong.includes(q.id)
                      );
                    })"
                  >
                    <tr :key="'incorrect' + q.index">
                      <td>Q{{ q.index + 1 }}</td>
                      <td style="max-width: 300px" v-html="q.Question"></td>
                      <td>
                        <span v-if="q.ansRangeEnd">
                          {{ q.ansRangeStart }} &lt;=
                        </span>
                        <span
                          v-for="(ans, aix) in q.Answers"
                          :key="aix"
                          :style="{
                            color:
                              ['A', 'B', 'C', 'D', 'E'][aix] == q.CorrectAnswer
                                ? 'green'
                                : ['A', 'B', 'C', 'D', 'E'][aix] ==
                                  t.entry.answers[q.index]
                                ? 'red'
                                : ''
                          }"
                          >{{ ["A", "B", "C", "D", "E"][aix] }}: {{ ans.Answer
                          }}<br
                        /></span>
                        <span v-if="q.ansRangeEnd">
                          &lt;= {{ q.ansRangeEnd }}
                        </span>
                      </td>
                      <td>{{ q.Marks }}</td>
                    </tr>
                    <tr v-if="q.Explanation" :key="q.id">
                      <td align="center" colspan="4">
                        <span
                          v-html="`<h5>Explanation:</h5><br>` + q.Explanation"
                        ></span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </span>
            <div v-if="t.grade.marks < t.grade.total" class="mt12 mlauto">
              <h6 class="mb12 mt20">Areas for Improvement</h6>
              <p
                v-for="topic in getTopicCounts(t.grade.wrongTopics).slice(0, 3)"
                :key="topic"
                class="fs16"
              >
                {{ topic[0].split("AG - ")[1] }} - {{ topic[1] }} wrong
              </p>
            </div>

            <span v-if="t.grade.marks < t.grade.total">
              <h6 class="mt20">Unanswered Answers</h6>
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Question</th>
                    <th>Answers</th>
                    <th>Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="q in t.entry.questions.filter((q, i) => {
                      q.index = i;
                      return (
                        q.type != 'openEnded' &&
                        t.grade.incomplete.includes(q.id)
                      );
                    })"
                  >
                    <tr :key="'unanswered' + q.index">
                      <td>Q{{ q.index + 1 }}</td>
                      <td style="max-width: 400px" v-html="q.Question"></td>
                      <td>
                        <span v-if="q.ansRangeEnd">
                          {{ q.ansRangeStart }} &lt;=
                        </span>
                        <span
                          v-for="(ans, aix) in q.Answers"
                          :key="aix"
                          :style="{
                            color:
                              ['A', 'B', 'C', 'D', 'E'][aix] == q.CorrectAnswer
                                ? 'green'
                                : ['A', 'B', 'C', 'D', 'E'][aix] ==
                                  t.entry.answers[q.index]
                                ? 'red'
                                : ''
                          }"
                          >{{ ["A", "B", "C", "D", "E"][aix] }}: {{ ans.Answer
                          }}<br
                        /></span>
                        <span v-if="q.ansRangeEnd">
                          &lt;= {{ q.ansRangeEnd }}
                        </span>
                      </td>
                      <td>{{ q.Marks }}</td>
                    </tr>
                    <tr v-if="q.Explanation" :key="q.id">
                      <td
                        align="center"
                        colspan="3"
                        v-html="'<h5>Explanation:</h5><br>' + q.Explanation"
                      ></td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </span>
            <div v-if="t.grade.marks < t.grade.total" class="mlauto">
              <h6 class="mb12 mt20">Areas for Improvement</h6>
              <p
                v-for="topic in getTopicCounts(t.grade.incompleteTopics).slice(
                  0,
                  3
                )"
                :key="topic"
                class="fs16"
              >
                {{ topic[0].split("AG - ")[1] }} - {{ topic[1] }} wrong
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$mq != 'mobile'" class="create_package">
        <h6 class="mb20 pl8 pt20">Add Tests</h6>
        <div class="vertical flex">
          <div class="_section spaced flex">
            <p style="margin: auto 0px;">Type</p>
            <dropdown
              :v="type"
              :value.sync="type"
              class="package_input ml0 mt2"
            >
              <option value="" selected disabled>Package</option>
              <option v-for="o in offerings" :key="o.id" :value="o.name">{{
                o.name
              }}</option>
              <!--option value="full">Full (65 questions)</option>
              <option value="half">Half (35 questions)</option>
              <option value="2017">Past Paper - 2017 (65 qs)</option>
              <option value="2018">Past Paper - 2018 (65 qs)</option>
              <option value="2019">Past Paper - 2019 (65 qs)</option>
              <option value="2020">Past Paper - 2020 (65 qs)</option>
              <option value="AG-ACS">Topic Based: Acoustics</option>
              <option value="category">Category - Based</option -->
            </dropdown>
          </div>
          <div
            class="_section spaced flex"
            style="padding: 12px 8px;"
            v-if="offeringContents.length > 0"
          >
            <p style="margin: auto 0px;">Contains</p>
            <div>
              <div v-for="oc in offeringContents" :key="oc.id">
                {{ oc.quantity }} x {{ oc.quiz.title }} Test
              </div>
            </div>
          </div>
          <div v-if="type == 'category'" class="_section spaced flex">
            <p style="margin: auto 0px;">No. of Questions</p>
            <input
              v-model="nQuestions"
              class="package_input mt4 ui input"
              type="number"
            />
          </div>
          <div v-if="type == 'category'" class="_section spaced flex">
            <p style="margin: auto 0px;">Categories</p>
            <dropdown class="mt4" multiple>
              <option value="" selected disabled>Select Categories </option>
              <option v-for="c in categories" :key="c.id" :value="c.id">{{
                c.name.split("AG - ")[1]
              }}</option>
            </dropdown>
          </div>
          <div class="pl8 pr12 py12 spaced flex">
            <p class="bold">Total Price</p>
            <p class="bold">₹{{ costPerTest }}</p>
          </div>
        </div>
        <button @click="launchIM" class="brown500 wfill mt20">Proceed</button>
      </div>
    </div>
  </div>
</template>

<script>
import stat from "../components/stat";
import dropdown from "../components/dropdown";
import progressbar from "../components/progressbar";
import { Chart, registerables } from "chart.js";
export default {
  name: "Acegate",
  components: { stat, dropdown, progressbar },
  data() {
    return {
      type: "",
      loading: false,
      alphabets: ["A", "B", "C", "D", "E"],
      freeQuizzes: [],
      nTests: 1,
      nQuestions: 32,
      quizzes: [],
      categories: [],
      category: [],
      tests: [],
      taken: [],
      activityChart: null,
      packages: [],
      offerings: [],
      defaults: {
        type: "full",
        nTests: 1,
        nQuestions: 32,
        categories: []
      }
    };
  },
  mounted() {
    this.loading = true;
    this.$req
      .get("acegate-offerings")
      .then(r => {
        this.offerings = r.data;
        // Getting users quiz entries
        let ids = [];
        this.offerings.map(o => {
          o.packages.map(p => {
            ids.push(p.quiz.id);
            if (o.price == 0) {
              this.freeQuizzes.push(p.quiz.id);
            }
          });
        });

        this.$req
          .post(
            "qentriesnquizzes",
            {
              ids,
              userId: this.$bus.user.id
            },
            this.$bus.headers
          )
          .then(r => {
            let data = r.data;
            this.taken = data.entries;
            this.quizzes = data.quizzes;
            this.loading = false;
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));

    this.$req
      .post("runQuery", {
        query: "acegate-packages",
        userId: this.$bus.user.id
      })
      .then(r => {
        this.packages = r.data;
      })
      .catch(e => console.log(e));
    this.initializeActivityChart();
  },
  watch: {
    takenCount: function() {
      this.initializeActivityChart();
    },
    purchasedCount: function() {
      this.initializeActivityChart();
    }
  },
  computed: {
    costPerTest: function() {
      let type = this.type;
      if (type) {
        let q = this.offerings.find(q => q.name == type);
        return q.price;
      }
      return "-";
    },
    cost: function() {
      let c = this.costPerTest * this.nTests;
      return c < 0 ? 0 : c;
    },
    offeringContents: function() {
      let o = this.offerings.find(o => o.name == this.type);
      return o ? o.packages : [];
    },
    available: function() {
      let avbl = {};
      /* eslint-disable-next-line */
      for (let [k, v] of Object.entries(this.packageBreakdown)) {
        avbl[k] = (this.takenBreakdown[k] || 0) < this.packageBreakdown[k];
      }
      return avbl;
    },
    packageBreakdown: function() {
      let reduced = this.packages.reduce((s, p) => {
        s[p.type] = s[p.type] || [];
        s[p.type].push(p);
        return s;
      }, {});
      let counts = {};
      for (let k of Object.keys(reduced)) {
        counts[k] = reduced[k].reduce((s, p) => s + p.tests, 0);
      }
      return counts;
    },
    takenBreakdown: function() {
      if (!this.taken) return {};
      let reduced = this.taken.reduce((s, p) => {
        let t = p.title;
        s[t] = s[t] || [];
        s[t].push(p);
        return s;
      }, {});
      let counts = {};
      for (let k of Object.keys(reduced)) {
        counts[k] = reduced[k].length;
      }
      return counts;
    },
    purchasedCount: function() {
      return Object.values(this.packageBreakdown).reduce((s, v) => s + v, 0);
    },
    takenCount: function() {
      return Object.values(this.takenBreakdown).reduce((s, v) => s + v, 0);
    },
    gradedEntries: function() {
      return this.taken.map(ent => {
        ent.grade = this.getGrade(ent);
        return ent;
      });
    },
    averageGrade: function() {
      let avg =
        this.gradedEntries.reduce((s, ge) => {
          s += (ge.grade.marks * 100) / ge.grade.total;
          return s;
        }, 0) / this.gradedEntries.length;
      return Math.round(avg);
    }
  },
  methods: {
    isFree: function(name) {
      return this.freeQuizzes.includes(this.getQuizId(name));
    },
    getMoreQuizEntries: function() {
      this.$req.post("getAcegateQuizEntries", {});
    },
    launchIM: function() {
      /* eslint-disable */
      if (this.type) {
        let quiz = this.offerings.find(q => q.name == this.type);
        if (quiz.instamojoLink) {
          Instamojo.open(
            `${quiz.instamojoLink}?buyer_name=${this.$bus.user.name}&buyer=${this.$bus.user.email}`
          );
        } else {
          for (let p of quiz.packages) {
            this.$req
              .post(
                "acegate-packages",
                {
                  type: p.quiz.title,
                  tests: p.quantity,
                  user: this.$bus.user.id,
                  paymentId: ""
                },
                this.$bus.headers
              )
              .catch(e => console.log(e));
          }
          this.$bus.notify("Quiz has not been linked");
        }
      } else {
        this.$bus.notify("Please select an Acegate Package");
      }
    },
    getAnsIndex: function(ans) {
      let anss = ["a", "b", "c", "d", "e", "f", "g"];
      let ix = anss.findIndex(a => a == ans.toLowerCase());
      return ix;
    },
    getTopicCounts: function(topics) {
      let counts = topics.reduce((s, t) => {
        s[t.name] = s[t.name] || 0;
        s[t.name] += 1;
        return s;
      }, {});
      let sorted = Object.entries(counts).sort((t1, t2) => t2[1] - t1[1]);
      return sorted;
    },
    getQuizByName: function(name) {
      let quiz = this.quizzes.find(q => q.title == name);
      return quiz;
    },
    getQuizId: function(name) {
      let quiz = this.getQuizByName(name);
      return quiz ? quiz.id : "";
    },
    takeQuiz: function(k) {
      // Getting quiz id
      let quiz = this.getQuizByName(k);
      let id = (quiz || {}).id;
      if (!id) {
        this.$bus.notify("This quiz no longer exists");
        return;
      }
      this.$router.push(`quiz/acegate/${id}`);
    },
    openModal: function(id) {
      /* eslint-disable-next-line no-undef */
      $("#" + id).modal("show");
    },
    getGrade(ent) {
      let qs = ent.entry.questions;
      let ans = ent.entry.answers;
      let marks = 0;
      let total = 0;
      let wrong = [];
      let wrongTopics = [];
      let incomplete = [];
      let incompleteTopics = [];
      ans.map((answ, i) => {
        total += qs[i].Marks;
        if (!answ) {
          incomplete.push(qs[i].id);
          incompleteTopics.push(qs[i].Category);
          return;
        }
        if (qs[i].type == "openEnded") {
          // Evaluating open ended questions
          marks += parseInt(ent.grades[i] || 0);
          if (marks == 0) {
            wrong.push(qs[i].id);
            wrongTopics.push(qs[i].Category);
          }
          return;
        }
        if (qs[i].type == "numerical") {
          let ans = parseFloat(answ);
          let correct = parseFloat(qs[i].CorrectAnswer);
          let range = [qs[i].ansRangeStart, qs[i].ansRangeEnd];
          // Evaluating numerical questions
          if (range[0] && range[1] && ans >= range[0] && ans <= range[1]) {
            // If questions have a start and end range
            marks += qs[i].Marks;
          } else if (ans == correct) {
            marks += qs[i].Marks;
          } else {
            wrong.push(qs[i].id);
            wrongTopics.push(qs[i].Category);
          }
          return;
        }
        if (
          qs[i].CorrectAnswer.toLowerCase().replace(/\s/g, "") ==
          answ.toLowerCase()
        ) {
          // Evaluating standard questions
          marks += qs[i].Marks;
        } else {
          wrong.push(qs[i].id);
          wrongTopics.push(qs[i].Category);
        }
      });
      return {
        marks,
        total,
        wrong,
        wrongTopics,
        incomplete,
        incompleteTopics
      };
    },
    createPackage: function() {
      let obj = {};
      if (this.nTests <= 0) {
        this.notify("No. of tests have to be greater than 0");
        return;
      }
      obj["type"] = this.type;
      obj["tests"] = this.nTests;
      switch (this.type) {
        case "category":
          if (this.categories.length == 0) {
            this.notify("No categories entered");
            return;
          }
          if (this.nQuestions.length <= 0) {
            this.notify("No. of questions have to be greater than 0");
          }
          obj["categories"] = this.categories;
          obj["questions"] = this.nQuestions;
          break;
      }
      this.$req
        .post(
          "acegate-packages",
          {
            ...obj,
            user: this.$bus.user.id
          },
          this.$bus.headers
        )
        .then(r => {
          for (let [v, k] of Object.entries(this.defaults)) {
            this[k] = v;
          }
          this.packages.push(r.data);
        })
        .catch(e => console.log(e));
    },
    initializeActivityChart: function() {
      Chart.register(...registerables);
      var ctx = document.getElementById("activityChart");
      if (this.activityChart) {
        this.activityChart.destroy();
      }
      let taken = this.takenCount;
      let remaining = this.purchasedCount - this.takenCount;
      this.activityChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Taken", "Remaining"],
          datasets: [
            {
              label: "# of Tests",
              data: [taken, remaining],
              backgroundColor: [
                "#8D6235",
                "#f5f5f5",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)"
              ]
            }
          ]
        },
        options: {
          cutout: "80%",
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.acedge_navbar {
  width: 100%;
  background: #eccb82;
}
.create_package {
  padding: 12px 20px;
  max-width: 500px;
  min-width: 350px;
  margin-left: 40px;
  position: absolute;
  right: 0px;
  top: 0px;
  background: #f9f9f9;
  height: 100vh;
}
.package_input {
  width: 50px;
  background: none;
  outline: none;
  padding: 4px;
  border: 1px solid #eee;
  text-align: right;
}
.package_input:focus {
  border: 1px solid #b98744;
}

.create_package ._section {
  border-bottom: 1px solid #eee;
  padding: 8px;
}
.create_package ._section .ui.dropdown {
  background: none;
  width: 150px;
  border: 1px solid #eee;
}
.measure_label {
  width: 100px;
  height: 100px;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  display: flex;
  flex-direction: column;
}

.test_card {
  width: 200px;
  height: 300px;
  padding-bottom: 24px;
  border: 1px solid #eee;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  display: inline-flex;
  flex-direction: column;
}
.test_card .progressbar {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.test_history {
  padding: 0px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #eee;
  border-bottom: none;
  max-width: 450px;
  position: relative;
  overflow: hidden;
  display: flex;
  padding-bottom: 4px;
}
</style>
